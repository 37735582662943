@import "../..//Assets/colors.scss";

.steps {

    margin: 3% 30%;

    @media (max-width: 1000px) {
    margin: 3% 10%;
    }

    .date-picker-center {
        margin: 0 30%;

        @media (max-width: 1000px) {
            margin: 0 0;
        }
    }

    .validation-msg {
        color: red;
        margin-top: 3px;
    }

    .fix-div-size {
        height: 400px;
        overflow: auto;
    }

    .marginBottom10 {
        margin-bottom: 20px;
    }

    .arrowBG {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        background: #c9c9c9;
        text-align: center;
        padding: 10px;
        margin-right: 15px;
    }

    .arrow-edit {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        background: #fc5033;
        text-align: center;
        padding: 12px 16px;
        margin-right: 30px;
    }

    .form-control:focus {
        color: #212529;
        background-color: #fff;
        border-color: #fc5033;
        outline: 0;
        box-shadow: none;
    }

    .continue-steps {
        background: #fc5033;
        color: white;
        border-color: #fc5033;
        padding: 10px 100px;
        border: 1px solid #fc5033;
        border-radius: 4px;
    }

    .continue-steps:disabled,
    .continue-steps[disabled],
    .continue-steps:disabled:hover{
        cursor: not-allowed;
        color: white;
    }

    .continue-steps:hover {
        background: #fc5033;
        color: black;
        border-color: #fc5033;
    }

    .checkbox-container {
        background: #c9c9c9;
        width: 45%;
        text-align: center;
        justify-content: space-between;
        margin: 1% auto;
        padding: 3% 5%;
        border-radius: 10px;

        h2 {
            margin-bottom: 0;
            color: white;
        }
    }

    .checkbox-container:hover {
        background: #fc5033;
        cursor: pointer;

        // box-shadow: 1px 3px 1px black;
        h2 {
            color: white;
        }
    }

    .active-container {
        background: #fc5033;
        cursor: pointer;

        // box-shadow: 1px 3px 1px black;
        h2 {
            color: white;
        }
    }

    .checkbox {
        position: relative;
        right: 8px;
        -webkit-transform: scale(2);
    }

    .interests-section {
        background: #e2e2e2;
        padding: 10px;
        border-radius: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        width: fit-content;
        border: 1px solid #e2e2e2;
    }

    .interests-section-select {
        background: #fc5033;
        border: 1px solid #fc5033;
        color: #fff;
    }

    .about-well {
        padding: 20px;
        box-shadow: 2px 2px 20px #e2e2e2;
        border-radius: 20px;
    }

    .selctbox-habits {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        padding-left: 10px;
    }

    .custom-file-upload {
        margin-right: 40px;
        margin-bottom: 20px;
    }

    .profile-image {
        height: 250px;
        width: 250px;
        background: linear-gradient(rgba(249, 161, 88, 0.5), rgba(249, 112, 147, 0.5));
        border-radius: 50%;
        position: relative;
        text-align: center;

        &__text {
            position: absolute;
            bottom: 10%;
            left: 30%;
            margin: auto;
            color: white;
            border-bottom: 1px solid white;
        }
    }

    .photo-time {
        overflow-x: scroll;
    }

    .gallery-section {
        height: 250px;
        width: 200px;
        background: linear-gradient(rgba(249, 161, 88, 0.5), rgba(249, 112, 147, 0.5));
        border-radius: 5%;
        position: relative;
        text-align: center;

        &__text {
            font-size: 50px;
            position: absolute;
            bottom: 0;
            margin: auto;
            color: white;
            width: 100%;
        }
    }

    .add-new-button {
        float: right;
    }

    @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .loading-spinner {
        width: 50px;
        height: 50px;
        border: 10px solid #f3f3f3; /* Light grey */
        border-top: 10px solid #383636; /* Black */
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
      }
}

.taskView {
    width: 100%;
    height: 2%;
    align-items: center;


    hr {
        border-radius: 5px;
        border-top: 0;
        height: 5px;
        margin: 0;
        opacity: 1;
    }
}


input[type=file].multi-file{ 
    color:transparent;
}
input, textarea:focus{
    outline: none;
}
input::file-selector-button {
    font-weight: bold;
    background: none;
    padding: 0.5em;
    border: none;
    border-radius: 3px;
}
.upload__img{
    &-wrap{
        display: flex;
        flex-wrap: wrap;
    }
    &-box {
        width: 97px;
        padding: 20px;
        margin-bottom: 12px;
        margin-right: 15px;
        position: relative;
        background-color: #edecec54;
        height: 97px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        img,video {
            width: 100%;
            object-fit: contain;
        }
    }
    &-close{
        position: absolute;
        display: inline-flex;
        right: 4px;
        top: 0;
        cursor: pointer;
        &::after{
            content: "x";
            font-size: 21px;
            color: #878787;
            border: 0;
            font-family: monospace;
            line-height: 20px;
        }
    }

}
.error {
    color: red;
    margin: 0;
    text-align: left;
    font-size: 16px;
    line-height: normal;
}

.images-block {
    width: 100%;
                padding-left: 10px;
                margin-bottom: 5px;
                border-radius: 5px;
                border: 1px solid #ccc;
                font-size: 16px;
                color: #333;
}

#range-slider-yellow {
    background: grey;
  }
  
  #range-slider-yellow .range-slider__range {
    background: #fc5033;
    transition: height 0.3s;
  }
  
  #range-slider-yellow .range-slider__thumb {
    background: #fc5033;
    transition: transform 0.3s;
  }
  
  #range-slider-yellow .range-slider__thumb[data-active] {
    transform: translate(-50%, -50%) scale(1.25);
  }
  
  #range-slider-yellow .range-slider__range[data-active] {
    height: 16px;
  }


  .slider-text {
    display: flex;
    justify-content: space-between;
  }