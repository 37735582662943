.eula {
    padding-top: 20px;
    width: 60%;
    margin: auto;

    h2 {
        color: rgb(63, 69, 84);
        margin-top: 3%;
        margin-bottom: 1%;
        font-family: "Gabriela, sans-serif";
        font-weight: 700;
    }

    p {
        font-size: 16px;
        color: rgb(74, 80, 115);
    }

    ul li {
        color: rgb(74, 80, 115);
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .eula {
        width: 80%;
    }
}