.navbar {
    padding: 10px 10% !important;

    @media (max-width: 767px) {
        padding: 2% 10% !important;    
    }

    @media (max-width: 340px) {
        padding: 2% 5% !important;    
    }
}

.bg-header {
    background: white !important;
}

.navbar-brand {
    img {
        width: 70px;
    }
}

.navbar-collapse {
    justify-content: flex-end;

    a {
        text-align: center;
    }

    li {
        margin: auto 30px;
        font-family: 'AvenirLTStd', sans-serif;
        font-weight: 400;

        a {
            text-decoration: none;
            color: #05083f;
            font-weight: bold;
        }

        a:hover {
            color: #fc5033;
        }
    }

    @media (max-width: 767px) {
        margin: 30px 0px;
        li {
            margin: 10px 0px;
            text-align: center;
            font-size: 25px;
        }
    }
}

.active {
    color: #fc5033 !important;
}

@media (max-width: 767px) {
    .show {
        background: white;
    }    
}

.button-header {
    padding: 10px 30px;
    background: white;
    border-color: #05083f;
    color: #05083f;
    border: 1px solid #05083f;
    border-radius: 4px;
}

.button-header:hover {
    background: #fc5033;
    color: white;
    border-color: #fc5033;
}