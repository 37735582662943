@import "../..//Assets/colors.scss";
 
 
 .faqs {
    margin: 5% 20%;

    h1 {
        font-family: "Gabriela, sans-serif";
        font-weight: 700;
        color: $secondary;
        font-size: 40px;
        line-height: 60px;
    }

    .faq-title {
        display: block;
        min-height: 48px;
        margin-bottom: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 4px;
        background-color: $white;
        box-shadow: 8px 8px 32px 0 $black;
        font-family: 'AvenirLTStd', sans-serif;
        font-weight: 400;
        color: $secondary;
        font-size: 18px;
        line-height: 24px;
        padding: 12px 30px;
        text-decoration: none;
    }

    .faqs-title {
        padding-right: 30px;
    }

    .faq-title:hover {
        // background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(90deg, #F9A158 0%, #F97093 104.78%);
        color: $white;
        background: #fc5033;

        cursor: pointer;
    }

    .faq-title-active {
        // background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(90deg, #F9A158 0%, #F97093 104.78%);
        // ;
        // ;
        background: #fc5033;
        color: $white;
    }

    .faqs-ans {
        padding: 30px;
        border: 1px solid #d0d9df;
        border-radius: 4px;

        h2 {
            margin-bottom: 10px; 
            font-family: 'AvenirLTStd', sans-serif;
            font-weight: 400;
            color: $secondary;
            font-size: 26px;
            line-height: 36px;
        }

        .ans-faq {
            font-family: 'AvenirLTStd', sans-serif;
            color: $secondary;
            font-size: 16px;
            line-height: 24px;
        }
    }

    @media (max-width: 767px) {
        margin: 2%;

        .faqs-title {
            padding-right: 12px;
        }

        .faqs-ans {
            // margin: 5%;
            border: unset;
        }
    }
}
