@import "../..//Assets/colors.scss";

.min-width-apps {
    height: 35vh;
}

.apps {
    padding: 30px;

    h2 {
        font-family: "Gabriela, sans-serif";
        color: $secondary;
        font-size: 48px;
        line-height: 60px;
        font-weight: 900;
        text-align: center;
    }

    .buttons {
        width: 50%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-top: 6%;

        @media (max-width: 500px) {
            margin-top: 10%;
        }
    }

    @media (max-width:1250px) {
        .buttons {
            width: 80%;
        }
    }

    @media (max-width: 767px) {
        h2 {
            font-size: 24px;
            line-height: 40px;
        }

        .buttons {
            display: block;

            a {
                display: block;
                text-align: center;
                margin: 4% 0;
            }
        }
    }
}