@import "../..//Assets/colors.scss";

.onboarding {
    overflow-x: hidden;

    &__btn-start {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;

        .button-start-here {
            background: #fc5033;
            color: white;
            border-color: #fc5033;
            padding: 10px 30px;
            border: 1px solid #fc5033;
            border-radius: 4px;
            width: 320px;
        }

        .button-start-here:hover {
            background: #fc5033;
            color: black;
            border-color: #fc5033;
        }
    }

    &__not-everyone {
        color: #05083fba;
        font-weight: lighter;
        width: 100%;
        margin: 0;
        font-size: 18px;
    }

    &__not-everyone::before {
        content: " *";
        color: #05083f;
        margin-right: 5px;
    }

    &-img {
        width: 55%;
        position: relative;

        @media (max-width: 768px) {
            width: 100%;
            margin-top: -30%;
        }

        img {
            max-width: 100%;
            width: 100%;
            object-fit: contain;
            object-position: left top;
            position: relative;

            @media (max-width: 768px) {
                transform: scale(1.2);
            }
        }
    }

    &_flex {
        display: flex;


        @media (max-width: 768px) {
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            position: relative;
            min-height: 350px;
        }

    }

    &__quote h2 {
        font-family: "Gabriela, sans-serif";
        color: #05083f;
        font-weight: 700;
        width: 70%;
        font-size: 2rem;
        margin: 2% auto;
    }

    &__heading-section {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        align-content: center;
        padding: 0 30px;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 1300px) {
            padding: 150px 15px;
        }

        @media (max-width: 992px) {
            padding: 90px 0;
        }

        @media (max-width: 768px) {
            flex-wrap: wrap;
            margin: 0;
            width: 100%;
            height: 100%;
            z-index: 111;
            padding: 35px;
        }



        h1 {
            display: inline-block;
            font-family: "Gabriela, sans-serif";
            color: $secondary;
            font-size: 48px;
            line-height: 50px;
            font-weight: 700;

            @media (max-width: 1300px) {
                font-size: 38px;
                line-height: 43px;
            }

            @media (max-width: 992px) {
                font-size: 32px;
                line-height: 35px;
            }

        }

        .heading-description {
            margin: 15px 0 5px;
            margin-right: auto;
            margin-left: auto;
            font-family: 'AvenirLTStd', sans-serif;
            font-weight: 400;
            color: $secondary;
            font-size: 22px;
            line-height: 32px;

            @media (max-width: 1300px) {
                font-size: 17px;
                line-height: 28px;
            }

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 22px;
            }

            @media (max-width: 768px) {
                width: 100%;
            }
        }

        .heading-buttons {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .ios {
                margin-right: 20px;

                @media (min-width: 767px) and (max-width:1340px) {
                    margin-right: 10px;
                }

                @media (min-width: 767px) and (max-width:1027px) {
                    margin-bottom: 25px;
                }

                @media (max-width: 499px) {
                    margin-right: 15px;
                    margin-bottom: 25px;
                }
            }

            img {
                object-fit: contain;

                @media (max-width: 576px) {
                    height: auto;
                    max-width: unset;
                    margin-right: 5px !important;
                }

            }

            @media (max-width: 1300px) {
                margin-top: 40px;
            }
        }

        .heading-gif {
            display: flex;
            position: absolute;
            width: 100%;
        }

        @media (max-width:767px) {

            h1 {
                font-size: 30px;
                line-height: 34px;
            }

            .heading-description {
                font-size: 18px;
                line-height: 125%;
            }


            .heading-buttons {
                margin-top: 40px;
                width: 100%;

                img {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

    }

    &__how-work {
        padding: 80px 10%;
    }

    &__how-work-text {
        margin: auto;

        h2 {
            font-family: "Gabriela, sans-serif";
            color: $secondary;
            font-size: 48px;
            line-height: 60px;
            font-weight: 700;
        }


        .description {
            font-family: 'AvenirLTStd', sans-serif;
            color: $secondary;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.5px;
        }

        .description-li {
            text-align: left;
        }

        @media (max-width: 1300px) {
            .description {
                width: 100%;
            }
        }

        @media (max-width: 767px) {
            h2 {
                font-size: 24px;
                line-height: 40px;
            }

            .description {
                width: 100%;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    &__how-work-image {
        height: 700px;

        @media (max-width: 767px) {
            height: 350px;
        }
    }

    &__testimonials {
        // margin: 0 15%;
        // margin-top: 40px;

        h2 {
            font-family: "Gabriela, sans-serif";
            font-weight: 600;
            color: $secondary;
            font-size: 48px;
            line-height: 60px;
            // font-weight: 900;
        }

        h6 {
            display: flex;
            margin-top: 20px;
            font-family: 'AvenirLTStd', sans-serif;
            font-weight: 400;
            color: $secondary;
            font-size: 20px;

            hr {
                width: 20px;
                margin: auto 0;
                margin-right: 10px;
            }
        }

        .description {
            font-family: 'AvenirLTStd', sans-serif;
            font-weight: 400;
            color: $secondary;
            font-size: 22px;
            line-height: 33px;
            font-weight: 400;
            padding: 20px 305px;
        }

        .years {
            margin-left: 25px;
            font-family: 'AvenirLTStd', sans-serif;
            font-weight: 400;
            color: $secondary;
            font-size: 16px;
            margin-bottom: 40px;
        }

        .checked {
            color: orange
        }

        @media (max-width: 767px) {
            margin: 0 8%;
            text-align: center;

            h2 {
                width: 100%;
                font-size: 28px;
                line-height: 36px;
            }

            h6 {
                justify-content: center;
            }

            .description {
                width: 100%;
                font-size: 20px;
                line-height: 30px;
            }

            .years {
                margin-left: 0;
            }
        }
    }

    &__daily-kupenda {
        // margin: 10% 10% 3% 10%;

        h2 {
            font-family: "Gabriela, sans-serif";
            font-weight: 700;
            font-size: 48px;
            font-weight: bold;
            text-align: center;
        }

        @media (max-width: 767px) {
            margin: 25% 10% 3% 10%;

            h4 {
                font-size: 20px;
                line-height: 30px;
            }

            h1 {
                font-size: 28px;
                line-height: 36px;
            }
        }
    }

    &__why-kupenda {
        // margin: 8% 10%;

        .row {
            justify-content: space-between;
            --bs-gutter-x: 0;
        }

        h2 {
            font-size: 48px;
            line-height: 64px;
            text-align: center;
            color: $secondary;
            font-family: "Gabriela, sans-serif";
            font-weight: 700;
        }

        .why-kupenda-cards {
            width: 45%;
            padding: 30px 0;

            img {
                width: 120px;
                height: 120px;
                margin-right: 30px;
                float: left;
            }

            .card-title {
                font-family: "Gabriela, sans-serif";
                font-weight: 700;
                color: $secondary;
                font-size: 24px;
                line-height: 40px;
            }

            .card-description {
                font-family: 'AvenirLTStd', sans-serif;
                font-weight: 400;
                color: $secondary;
                font-size: 17px;
                line-height: 24px;
                width: 100%;
            }
        }

        @media (max-width: 1300px) {
            .card-description {
                width: 100% !important;
            }
        }

        @media (max-width: 767px) {
            font-size: 30px;

            .why-kupenda-cards {
                width: 100%;
                padding: 15px 0;
            }

            .card-description {
                width: 100% !important;
            }
        }
    }
}


.slick-arrow {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #fc5033 0%, #fc5033 104.78%) !important;
    width: 48px !important;
    height: 48px !important;
    border-radius: 4px;
}

.slick-prev {
    bottom: -70px;
    top: unset !important;
    left: unset !important;
}

.slick-next {
    bottom: -70px;
    top: unset !important;
    right: unset !important;
    left: 70px;
}

.slick-next:before,
.slick-prev:before {
    position: relative;
    top: 2px;
    font-size: 35px !important;
}


// ahmer css 

nav.navbar.navbar-expand-lg.navbar-light.bg-header,
.onboarding_flex,
.onboarding__quote,
.onboarding__how-work.row,
.sec-testimonial,
.onboarding__daily-kupenda,
.onboarding__why-kupenda,
.faqs {
    max-width: 1200px !important;
    padding: 5px !important;
    margin: auto !important;
}

.onboarding_flex .onboarding__heading-section {
    padding-left: 0;
}

.onboarding-img {
    margin-right: -70px;
    margin-top: 35px;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Montserrat' !important;
}

.heading-buttons a img {
    width: 150px !important;
    height: 50px !important;
}

ul.navbar-nav.mr-auto li a,
.card-title {
    font-family: 'Montserrat' !important;
}

.navbar-collapse li a:hover {
    border-bottom: 2px solid red;
}

.navbar-collapse li a {
    border-bottom: 2px solid rgba(255, 0, 0, 0);
}

.active {
    color: #fc5033 !important;
    border-bottom: 2px solid red !important;
}

.onboarding__quote h2.mb-0 {
    text-transform: capitalize;
    text-align: center;
    font-size: 27px;
    margin: 0 auto;
    width: 100%;
    padding: 75px 65px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: .5px;
    background: linear-gradient(to right, #000000 0%, #ff0000 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.onboarding__how-work.row {
    --bs-gutter-x: 0;
}

.col-md-6.m-auto.onboarding__how-work-text .description ul {
    padding-left: 0px;
}

.col-md-6.m-auto.onboarding__how-work-text {
    width: 55%;
    margin: 0 !important;
}

.col-md-6.onboarding__how-work-image {
    width: 45%;
}

.col-md-6.m-auto.onboarding__how-work-text .description ul li {
    margin: 15px 0;
}

.onboarding__testimonials {
    text-align: center;
    padding: 90px 5px !important;
}

.onboarding__testimonials h6 hr {
    display: none;
}

.onboarding__testimonials h6 {
    margin: 0;
    justify-content: center;
    padding: 0 0 20px;
}

.onboarding__testimonials .checked {
    font-size: 23px;
    margin: 0 3px;
}

.slick-next {
    right: 44.8% !important;
    left: unset !important;
}

.slick-prev {
    left: 45% !important;
}

.onboarding__daily-kupenda {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.onboarding__daily-kupenda h2 {
    width: 50%;
    font-size: 35px;
    padding-right: 70px;
    text-align: left;
    line-height: 45px;
    margin: 0;
    background: linear-gradient(to right, #ff0000 0%, #c000ff 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.onboarding__daily-kupenda div:nth-child(2) {
    width: 50% !important;
}

.faqs h1,
.onboarding__heading-section h1,
.onboarding__how-work-text h2,
.onboarding__testimonials h2,
.onboarding__why-kupenda h2 {
    background: linear-gradient(to right, #000000 0%, #ff0000 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.onboarding__why-kupenda {
    padding: 50px 5px !important;
}

.faqs h1 {
    font-size: 45px !important;
}

.onboarding {
    background: #fff5ee;
}

.faq-title {
    box-shadow: unset !important;
}

.new-slider-main {
    padding: 0 !important;
    margin: 0 !important;
}

.new-slider-main .description {
    padding: 0 !important;
    margin: 0 !important;
}

.img-testi-div img {
    width: 250px;
    height: 250px;
    display: block;
    margin: 0 auto;
}

.onboarding__how-work.row .onboarding__how-work-image {
    height: auto;
}

.onboarding__how-work.row {
    align-items: center;
}



.Icon-howItWorks h2 a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-slider-div h6 {
    font-weight: 600 !important;
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Montserrat' !important;
}

.new-slider-div .description {
    width: 230px;
    margin: auto !important;
    line-height: 26px;
    margin-top: 10px !important;
}

.new-slider-div .slick-prev,
.new-slider-div .slick-next {
    background: transparent !important;
    z-index: 1;
    top: 35% !important;
}

.new-slider-div .slick-next {
    right: 15.8% !important;
}

.new-slider-div .slick-prev {
    left: 15.8% !important;
}

.new-slider-div .slick-next:before,
.new-slider-div .slick-prev:before {
    color: #000000 !important;
    background: linear-gradient(to right, #000000 0%, #ff0000 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}



.lightbox {
    display: none;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
}

.lightbox:target {
    outline: none;
    display: block;
}

.fade:not(.show) {
    opacity: 1 !important;
}

#videoModal {
    border-radius: 0;
    width: 100%;
    display: block;
    margin: auto;
}

#videoModal .modal-header {

    background: transparent !important;
    border: unset !important;

}

#videoModal .modal-header h3 {
    font-size: 18px;
    line-height: 22px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 5px;
}

#videoModal .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-height: none;
    overflow: hidden;
}

div#videoModal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#videoModal .modal-footer:empty {
    display: none !important;
}

#videoModal .close {
    background: #DB00B8;
    color: #fff;
    font-size: 24px;
    margin: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    text-shadow: none;
    top: 0;
    width: 38px;
}

.new-slider-main .slick-dots li button:before {
    font-size: 17px;
    width: fit-content;
    height: auto;
    margin-top: 10px;
}

.sec-testimonial ul.slick-dots {
    display: none !important;
}

.modal-body video {
    height: 483px;
}

.onboarding__quote {
    background: white;
    border-radius: 15px;
    margin: 40px auto !important;
}

.Icon-howItWorks .span-img {
    display: flex;
    width: 50px;
    position: relative;
    margin-right: 5px;

}

.Icon-howItWorks .span-img img {
    width: 100%;
}

.Icon-howItWorks .span-img:after {
    content: "";
    border: 1px solid black;
    width: 100%;
    height: 100%;
    position: absolute;
    animation: playbtn 3s linear infinite;
    -webkit-animation: playbtn 3s linear infinite;
    border-radius: 50%;
}
.Icon-howItWorks .span-img:before {
    content: "";
    border: 1px solid black;
    width: 100%;
    height: 100%;
    position: absolute;
    animation: playbtn-2 3s linear infinite;
    -webkit-animation: playbtn-2 3s linear infinite;
    border-radius: 50%;
    animation-delay: 1.5s;
}
@keyframes playbtn {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
}



    100% {
        transform: scale(1.4);
        -webkit-transform: scale(1.4);
        -moz-transform: scale(1.4);
        -ms-transform: scale(1.4);
        opacity: 0;
        -o-transform: scale(1.4);
}

}
@keyframes playbtn-2 {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
}


    100% {
        transform: scale(1.4);
        -webkit-transform: scale(1.4);
        -moz-transform: scale(1.4);
        -ms-transform: scale(1.4);
        -o-transform: scale(1.4);
        opacity: 0;


}
}











@media (max-width:1025px) {

    nav.navbar.navbar-expand-lg.navbar-light.bg-header,
    .onboarding_flex,
    .onboarding__quote,
    .onboarding__how-work.row,
    .sec-testimonial,
    .onboarding__daily-kupenda,
    .onboarding__why-kupenda,
    .faqs {
        max-width: 90% !important;
    }

    .navbar-collapse li {
        margin: auto 15px;
        font-size: 14px;
    }

    .onboarding__heading-section h1 {
        font-size: 34px;
        line-height: 38px;
    }

    .onboarding__heading-section .heading-buttons {
        margin-top: 0px;
    }

    .onboarding__heading-section .heading-buttons .ios {
        margin-bottom: 0;
    }

    .onboarding__quote h2.mb-0 {
        font-size: 21px;
        padding: 60px 10px;
        line-height: 34px;
    }

    .onboarding__how-work-text h2 {
        font-size: 41px;
        line-height: normal;
    }

    .onboarding__how-work-text .description {
        font-size: 18px;
        line-height: 24px;
    }

    .new-slider-div .slick-next {
        right: 1% !important;
    }

    .new-slider-div .slick-prev {
        left: 1% !important;
    }

    new-slider-main {
        max-width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .onboarding__testimonials h2 {
        font-size: 37px;
        line-height: normal;
    }

    .onboarding__testimonials.sec-testimonial .description {
        padding: 20px 155px;
    }

    .onboarding__daily-kupenda h2 {
        font-size: 28px;
        padding-right: 35px;
        line-height: 40px;
    }

    .onboarding__why-kupenda h2 {
        font-size: 40px;
        line-height: normal;
    }

    .onboarding__why-kupenda .why-kupenda-cards {
        width: 49%;
    }

    .onboarding__why-kupenda .why-kupenda-cards .card-description {
        font-size: 16px;
    }

    .onboarding__why-kupenda .why-kupenda-cards img {
        height: 145px;
    }

}

@media (max-width:768px) {
    .onboarding__heading-section .heading-description {
        font-size: 22px;
        line-height: 30px;
    }

    .onboarding__heading-section {
        text-align: center;
        padding: 45px 0 70px;
        justify-content: center;
    }

    .onboarding-img {
        margin: 40px auto;
        margin-bottom: 70px;
        width: 80%;
    }

    .onboarding__quote h2.mb-0 {
        font-size: 24px;
        padding: 60px 10px;
    }

    .onboarding__how-work.row {
        flex-direction: column;
    }

    .col-md-6.m-auto.onboarding__how-work-text {
        width: 100%;
        text-align: center;
    }

    .onboarding__how-work-text .description {
        font-size: 19px;
        line-height: 31px;
    }

    .onboarding__testimonials.new-slider-main {
        margin: 50px auto !important;
    }

    .onboarding__testimonials h2 {
        font-size: 36px;
        line-height: normal;
        font-weight: bold;
    }

    .col-md-6.onboarding__how-work-image {
        width: 100%;
    }

    .onboarding__testimonials {
        padding: 30px 5px !important;
    }

    .onboarding__testimonials.sec-testimonial .description {
        padding: 20px 55px;
    }

    .onboarding__testimonials.sec-testimonial .slick-next {
        right: 40% !important;
    }

    .onboarding__testimonials.sec-testimonial .slick-prev {
        left: 40% !important;
    }

    .onboarding__daily-kupenda h2 {
        font-size: 22px;
        line-height: 33px;
    }

    .onboarding__daily-kupenda {
        padding: 42px 5px 0px !important;
    }

    .onboarding__why-kupenda {
        padding: 40px 5px !important;
    }

    .onboarding__why-kupenda .why-kupenda-cards {
        width: 100%;
        padding: 8px 0;
    }

    .onboarding__why-kupenda h2 {
        margin-bottom: 25px;
    }

    .onboarding__why-kupenda .why-kupenda-cards img {
        height: auto;
        margin-right: 10px;
    }

    .faqs .row {
        flex-direction: column;
    }

    .faqs .col-md-6 {
        width: 100%;
    }

    .footer_wrp {
        padding-top: 100px !important;
        background-position: unset !important;
        padding-bottom: 0 !important;
    }

    .onboarding__daily-kupenda {
        flex-direction: column;
    }

    .onboarding__daily-kupenda h2 {
        width: 100%;
        margin: 50px auto -20px !important;
        text-align: center;
        padding: 0;
    }

    .onboarding__daily-kupenda div:nth-child(2) {
        width: 70% !important;
    }
}


@media (max-width:430px) {
    .onboarding__quote h2.mb-0 {
        font-size: 21px;
        padding: 60px 0px;
    }

    .onboarding__how-work-text h2 {
        font-size: 34px;
    }

    .onboarding__testimonials h2 {
        font-size: 34px;
    }

    .onboarding__testimonials.sec-testimonial .description {
        padding: 20px 0px;
    }

    .onboarding__testimonials.sec-testimonial .slick-next {
        right: 30% !important;
    }

    .onboarding__testimonials.sec-testimonial .slick-prev {
        left: 30% !important;
    }

    .onboarding__why-kupenda h2 {
        font-size: 34px;
    }

    .onboarding__why-kupenda .why-kupenda-cards .card-title {
        font-size: 21px;
    }

    .onboarding__why-kupenda .why-kupenda-cards .card-description {
        font-size: 15px;
    }

    .col-md-6.col-12.why-kupenda-cards {
        display: flex;
    }

    nav.navbar.navbar-expand-lg.navbar-light.bg-header,
    .onboarding_flex,
    .onboarding__quote,
    .onboarding__how-work.row,
    .sec-testimonial,
    .onboarding__daily-kupenda,
    .onboarding__why-kupenda,
    .faqs {
        max-width: 95% !important;
    }

    .onboarding__how-work-text .description {
        padding-left: 33px;
    }

    .footer_wrp img {
        position: unset;
    }

    .footer_wrpend {
        width: 60%;
        display: flex;
        justify-content: space-between;
    }





}
