.footer {
    overflow-x: hidden;
    margin-top: 50px;
    // position: absolute;
    // bottom: 0;
    // width: 100%;

    @media (max-width:767px) {
        margin-top: 20px;
        // position: unset;
    }
}
.footer_wrpend {
    justify-content: flex-end;
    align-items: center;
}



.footer_wrp {
    background-image: url('../../../public/footer_bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 30px;
    // padding-left: 214px;

    @media (max-width: 1650px) {
        // padding-left: 180px;
        padding-top: 200px;
    }

    @media (max-width: 1300px) {
        // padding-left: 120px;
        padding-top: 104px;
    }

    @media (max-width:767px) {
        padding-bottom: 20px;
        padding-top: 10px;
        display: block;
    }

    img {
        height: 75px;
        position: relative;

        @media (max-width: 1200px) {
            left: 8%;
        }

        @media (max-width: 1400px) {
            left: 9%;
        }

        @media (max-width:767px) {
            height: 44px;
            width: 50px;
            display: block;
            left: 0;
            bottom: -115px;
            margin: unset;
            margin-left: 10px;
        }
    }

    .footer-text {
        position: relative;
        font-weight: 700;
        color: #fff;
        font-size: 18px;
        letter-spacing: .5px;
        text-decoration: none;
        padding-right: 50px;

        @media (max-width:767px) {
            position: unset;
            display: block;
            text-align: center;
            margin-top: 3%;
            padding-right: 0;
        }
    }

    .footer-text:hover {
        color: black;
    }
}

.footer-contain{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px !important;
    padding: 5px !important;
    margin: auto !important;
}

.facebook-img {
    height: 30px !important;
    width: 30px;
    margin-right: 10px;
}

.twitter-img {
    height: 40px !important;
    width: 40px;
}


@media (max-width:1025px){
.footer_wrp {
    padding-top: 130px;
}
}
@media (max-width:768px){
.footer_wrp {
    padding-top: 100px !important;
    background-position: unset !important;
    padding-bottom: 0 !important; 
}

.facebook-img {
    height: 20px !important;
    width: 20px !important;
    margin-right: 5px !important;
}

.twitter-img {
    height: 25px !important;
    width: 25px !important;
}

}
@media (max-width:430px){
.footer_wrp img {
    position: unset;
}
.footer_wrpend {
    width: 60%;
    display: flex;
    justify-content: space-between;
}
}