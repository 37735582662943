
@import "../..//Assets/colors.scss";

.wingman {
    padding: 30px;
    margin: 0 5%;
    h2 {
        font-family: "Gabriela, sans-serif";
        font-weight: 700;
        color: $secondary;
        font-size: 48px;
        line-height: 60px;
        font-weight: 800;
        letter-spacing: .4px;
        text-transform: capitalize;
        text-align: center;
        margin: auto auto 40px auto;
        width: 80%;
        text-align: center;
    }

    p {
        width: 80%;
    margin-right: auto;
    margin-left: auto;
    font-family: 'AvenirLTStd', sans-serif;
    font-weight: 400;
    color: #05083f;
    font-size: 23px;
    line-height: 32px;
    margin-bottom: 2%;
    text-align: center;
    }

    &__card-section {
        margin-top: 8% !important;
        padding: 0 5%;

        .card-all {
            width: 500px;
        }

        .wingman-img {
            height: 700px;

            @media (max-width: 600px) {
                height: 400px;
            }
        }
    }

    @media (max-width: 1350px) {
        &__card-section {
    
            .card-all {
                width: 100%;
            }
        }

    }

    
    @media (max-width: 767px) {
        padding: 30px 0;

        h2 {
            font-size: 35px;
            line-height: 40px;
            width: 100%;
        }

        p {
            width: 100%;
            font-size: 18px;
            line-height: 125%;
        }

        &__card-section {   
            padding: 0 5%;
        }
    }

    .card-wingman { 
        margin: 4% 0;
        position: relative;
        font-family: "Gabriela, sans-serif";
        font-weight: 500;
        padding: 0;

        &__index {
            height: 50px;
    width: 50px;
    font-size: 20px;
    font-weight: bold;
    background: #ff0000;
    border-radius: 50%;
    text-align: center;
    padding: 10px;
    position: absolute;
    top: -28px;
    left: 42px;
    box-shadow: 0px 8px 10px #722222;
        }

        &__description {
            margin: 0 1%;
            padding: 30px;
            background: #e8e8e8;
            border-radius: 10px;
        }

        @media (max-width: 767px) {
            margin: 8% 0;
        }
    }
}






// ahmer css

.wingman {
    padding: 0;
    margin: 0;
    overflow: hidden;
}
.footer {
    overflow-x: hidden;
    margin-top: 0;
}
.main-sect-1,.sec-2-main {
    margin: auto!important;
    max-width: 1200px!important;
    padding: 5px!important;
}
.main-lex-sec-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-right-sec-1, .flex-left-sec-1 {
    width: 50%;
}
.flex-right-sec-1 div {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}
.flex-left-sec-1 h2, .flex-left-sec-1 p {
    margin: 10px 0 !important;
    padding: 0 !important;
    text-align: left;
    width: 100%;
}
p.p-1 {
    margin: 25px 0 15px !important;
}
.sec-2-main .row.wingman__card-section,.sec-2-main .row.wingman__card-section .col-md-6 {
    padding: 0 !important;
    margin: 0 !important;
}
.sec-2-main .row.wingman__card-section .col-md-6 .row.card-all.m-auto {
    width: 100%;
    display: grid;
}
.sec-2-main,.main-sect-1 {
    margin: 50px auto !important;
}
.sec-2-main .card-wingman__description {
    background: #ff6900;
    color: white;
    font-weight: 600;
    letter-spacing: .4px;
    font-family: 'AvenirLTStd', sans-serif;
    padding-top: 35px;
    font-size: 20px;
}
.col-md-6.faqs-ans h2 {
    margin: 0;
    text-align: left;
    margin-bottom: 20px;
}

@Media(max-width:1025px){
.main-sect-1,.sec-2-main {
    margin: auto!important;
    max-width: 90%!important;
    padding: 5px!important;
}
.sec-2-main,.main-sect-1 {
    margin: 50px auto !important;
}
.flex-left-sec-1 p {
    font-size: 19px;
}
}
@Media(max-width:768px){
    .main-lex-sec-1 {
        flex-direction: column;
    }
    .flex-right-sec-1, .flex-left-sec-1 {
        width: 100%;
    }
    .flex-left-sec-1 p {
        font-size: 21px;
    }
    .flex-left-sec-1 h2 {
        font-size: 45px;
    }








}















