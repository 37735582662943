@import "../..//Assets/colors.scss";

.wingman {
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.footer {
    overflow-x: hidden;
    margin-top: 0;
}

.main-sect-1,
.sec-2-main {
    margin: auto !important;
    max-width: 1200px !important;
    padding: 5px !important;
}

.main-lex-sec-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-left-sec-1 {
    padding-right: 2%;
}

.flex-right-sec-1,
.flex-left-sec-1 {
    width: 50%;
}

.flex-right-sec-1 div {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

.flex-left-sec-1 h2,
.flex-left-sec-1 p {
    margin: 10px 0 !important;
    padding: 0 !important;
    text-align: left;
    width: 100%;
}

p.p-1 {
    margin: 25px 0 15px !important;
}

.sec-2-main .row.wingman__card-section,
.sec-2-main .row.wingman__card-section .col-md-6 {
    padding: 0 !important;
    margin: 0 !important;
}

.sec-2-main .row.wingman__card-section .col-md-6 .row.card-all.m-auto {
    width: 100%;
    display: grid;
}

.sec-2-main,
.main-sect-1 {
    margin: 50px auto !important;
}

.sec-2-main .card-wingman__description {
    background: #ff6900;
    color: white;
    font-weight: 600;
    letter-spacing: .4px;
    font-family: 'AvenirLTStd', sans-serif;
    padding-top: 35px;
    font-size: 20px;
}

.col-md-6.faqs-ans h2 {
    margin: 0;
    text-align: left;
    margin-bottom: 20px;
}

@Media(max-width:1025px) {

    .main-sect-1,
    .sec-2-main {
        margin: auto !important;
        max-width: 90% !important;
        padding: 5px !important;
    }

    .sec-2-main,
    .main-sect-1 {
        margin: 50px auto !important;
    }

    .flex-left-sec-1 p {
        font-size: 19px;
    }
}

@Media(max-width:768px) {
    .main-lex-sec-1 {
        flex-direction: column;
    }

    .flex-right-sec-1,
    .flex-left-sec-1 {
        width: 100%;
        padding-right: 0;
        padding-bottom: 2%;
    }

    .flex-left-sec-1 p {
        font-size: 21px;
    }

    .flex-left-sec-1 h2 {
        font-size: 45px;
        line-height: 48px;
    }
}


.guide_points {
    // color: #05083fba;
    font-weight: lighter;
    width: 100%;
    margin: 0;
    font-size: 18px;
    line-height: 34px;
}

.guide_points::before {
    content: " *";
    // color: #05083f;
    margin-right: 5px;
}

.matchmacker_img {
    width: 100%;
    object-fit: contain;
}

.form {
    background: white;
    border-radius: 15px;
    margin: 5% auto !important;
    width: fit-content;
    padding: 5%;

    form {
        max-width: 560px;
        margin: 0 auto;

        fieldset {
            margin-bottom: 15px;
            label {
                display: block;
                margin-bottom: 9px;
                font-weight: bold;
                color: #333;
                letter-spacing: 1px;
            }
            input, textarea {
                width: 100%;
                padding: 10px;
                margin-bottom: 5px;
                border-radius: 5px;
                border: 1px solid #ccc;
                font-size: 16px;
                color: #333;
                
            }
            input[type=file].multi-file{ 
                color:transparent;
            }
            input, textarea:focus{
                outline: none;
            }
            input::file-selector-button {
                font-weight: bold;
                background: none;
                padding: 0.5em;
                border: none;
                border-radius: 3px;
            }
            .upload__img{
                &-wrap{
                    display: flex;
                    flex-wrap: wrap;
                }
                &-box {
                    width: 97px;
                    padding: 20px;
                    margin-bottom: 12px;
                    margin-right: 15px;
                    position: relative;
                    background-color: #edecec54;
                    height: 97px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    img,video {
                        width: 100%;
                        object-fit: contain;
                    }
                }
                &-close{
                    position: absolute;
                    display: inline-flex;
                    right: 4px;
                    top: 0;
                    cursor: pointer;
                    &::after{
                        content: "x";
                        font-size: 21px;
                        color: #878787;
                        border: 0;
                        font-family: monospace;
                        line-height: 20px;
                    }
                }

            }
            .error {
                color: red;
                margin: 0;
                text-align: left;
                font-size: 16px;
                line-height: normal;
            }
        }

        .form_btn {
            display: block;
            text-align: center;
            button {
                margin-top: 30px;
                font-size: 18px;
                border: #ff6900;
                border-radius: 5px;
                padding: 2% 5%;
                margin-top: 6%;
                background: linear-gradient(to right, #F9A158 0%, #F97093 100%);
                color: white;
                font-weight: 700;
                font-size: 20px;
                letter-spacing: 1px;
            }
        }
    }
}