
@import "../..//Assets/colors.scss";

.term-condidtion {
    overflow-x: hidden;
    padding: 30px;
    h2 {
       
    font-family: "Gabriela, sans-serif";
        color: $secondary;
        font-size: 48px;
        line-height: 60px;
        font-weight: 900;
        text-align: center;
    }
    
    @media (max-width: 767px) {
        h2 {
            font-size: 24px;
            line-height: 40px;
        }
    }
}


.min-height-div {
    min-height: 60vh;
}

.privacy {
    padding-top: 20px;
    width: 60%;
    margin: auto;

    h2 {
        color: rgb(63, 69, 84);
        margin-top: 3%;
        margin-bottom: 1%;
        font-weight: bold;
    }

    p {
        font-size: 14px;
        color: rgb(74, 80, 115);
    }

    ul li {
        color: rgb(74, 80, 115);
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .terms-of-service {
        width: 80%;
        border: 1px solid red
    }
}