@import "../..//Assets/colors.scss";

.pageNotFound {
    text-align: center;
    margin: 10%;

    img {
        background: black;
        margin-bottom: 2%;
        border-radius: 10px;
    }

    h2 {
        font-family: "Gabriela, sans-serif";
        font-weight: 700;
        color: $secondary;
        font-size: 32px;
        line-height: 36px;
        margin-top: 20px;
    }

    p {
        font-family: "Gabriela, sans-serif";
        font-weight: 500;
        font-size: 16px;
    }
}